
table {
    border-radius: 20px !important;
    border-collapse: collapse !important;
    width: 100% !important;
    box-shadow: 0px 10px 20px #72717129 !important;
    opacity: 1; 
  }
  
  td {
    padding: 4px !important;  
  }

  td strong{
    padding-left: 2px !important ;
    padding-right: 2px !important ;
  }

  td p{
    text-align: center !important;
    justify-content: center !important;
    margin: 0.4rem 0 !important;
  }
  
  tr {
    background-color: #f9f9f9 !important;
  }

  tr:first-child { 
    background-color: #45759E !important;
    color: white !important;
  }

  tr:first-child p {
    margin: .5rem 0 !important;
  }

  tr{
    border: .5px solid rgb(196, 191, 191) !important;
  } 